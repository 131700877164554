import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faHouseUser,
  faHomeLg,
  faCoins,
  faUsers,
  faEuroSign,
} from "@fortawesome/pro-light-svg-icons";
import { StaticImage } from "gatsby-plugin-image";
import TestataEvento from "../../components/Evento/EventoTestata";
import EventoDiscipline from "../../components/Evento/EventoDiscipline";
import EventoDocenti from "../../components/Evento/EventoDocenti";
import EventoSponsors from "../../components/Evento/EventoSponsors";

export default function EventoPage({ data }) {
  const { eventiJson: evento } = data;

  return (
    <Layout
      pageTitle={`${evento.titolo_evento} dal ${evento.data_inizio} al ${evento.data_fine}`}
    >
      <div className="container my-5">
        <TestataEvento evento={evento} tipologia={evento.fsc_tipologia} />
        <div className="row mt-4 mb-3 px-2 py-4 shadow-lg text-center">
          <div className="col-12 col-md-4">
            <StaticImage
              src="../../images/eventi-fsc.jpg"
              className="img-fluid"
              alt={evento.titolo_evento}
              title={evento.titolo_evento}
            />
          </div>
          <div className="col-12 col-md-4">
            <h3>
              <FontAwesomeIcon icon={faCalendarAlt} /> Periodo svolgimento
            </h3>
            <p className="mb-3">
              {evento.data_inizio} - {evento.data_inizio}
            </p>
            <h3 className="mt-5">
              <FontAwesomeIcon icon={faHouseUser} /> Luogo svolgimento
            </h3>
            <p>
              {evento.fsc_svolgimento_regione} |{" "}
              {evento.fsc_svolgimento_provincia} |{" "}
              {evento.fsc_svolgimento_comune}
              <br />
              {evento.fsc_svolgimento_indirizzo}
            </p>
          </div>
          <div className="col-12 col-md-4">
            <h3>
              <i className="fal fa-clock"></i>
              Durata effettiva
            </h3>
            <p className="mb-3">{evento.durata} ore</p>
            <h3 className="mt-5">
              <FontAwesomeIcon icon={faHomeLg} /> Sede
            </h3>
            <p>{evento.fsc_svolgimento_luogo}</p>
          </div>
          <div className="col-12 mt-4">
            <div className="row text-center">
              <div className="col-12 col-md-4">
                <h3>
                  <FontAwesomeIcon icon={faCoins} /> Crediti ECM
                </h3>
                <p>{evento.crediti}</p>
              </div>
              <div className="col-12 col-md-4">
                <h3>
                  <FontAwesomeIcon icon={faUsers} />
                  Max Partecipanti
                </h3>
                {evento.numero_partecipanti}
              </div>
              <div className="col-12 col-md-4">
                <h3>
                  <FontAwesomeIcon icon={faEuroSign} />
                  Costo
                </h3>
                {evento.quota_partecipazione}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-3">
          <EventoDiscipline discipline={evento.professioni} />
        </div>
        <div className="row mt-4 mb-3">
          <div className="col-12">
            <h3>Contesto attivit&agrave;</h3>
            {evento.fsc_contesto_attivita}
          </div>
        </div>
        <div className="row mt-4 mb-3">
          <EventoDocenti docenti={evento.docenti} />
        </div>
        <div className="row mt-4 mb-3 text-center">
          <div className="col-12 col-md-4">
            <h3>Verifica presenza</h3>
            <p>{evento.verifica_presenza_partecipanti}</p>
          </div>
          <div className="col-12 col-md-4">
            <h3>Verifica apprendimento</h3>
            <p>{evento.verifica_apprendimento_partecipanti}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h3>Obiettivo formativo</h3>
            <p>
              {evento.area_obiettivo}
              <br />
              {evento.obiettivo_formativo}
            </p>
          </div>
        </div>
        <div className="row">
          <EventoSponsors sponsors={evento.sponsor} />
          <div className="col-12 col-md-6">
            <h3>Partner</h3>
            <p>{evento.partner}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    eventiJson(id: { eq: $id }) {
      id
      tipo_evento
      titolo_evento
      id_evento
      numero_edizione
      numero_partecipanti
      obiettivo_formativo
      partner
      professioni {
        disciplina
        professione
      }
      provider_id
      ragione_sociale
      regione
      sponsor
      sponsorizzato
      sponsorizzato_alimentazione_prima_infanzia
      verifica_apprendimento_partecipanti
      verifica_presenza_partecipanti
      alimentazione_prima_infanzia
      area_obiettivo
      competenze_processo
      consenso
      crediti
      data_fine
      data_inizio
      quota_partecipazione
      docenti {
        ruolo
        nome
        cognome
        codice_fiscale
      }
      durata
      estero
      finanziamento_altre_forme
      fsc_contesto_attivita
      fsc_data_fine
      fsc_discenti
      fsc_data_inizio
      fsc_durata
      fsc_livello_acquisizione
      fsc_livello_attivita
      fsc_oggetto_valutazione
      fsc_soggetto_valutatore
      fsc_svolgimento_comune
      fsc_svolgimento_indirizzo
      fsc_svolgimento_luogo
      fsc_svolgimento_provincia
      fsc_svolgimento_regione
      fsc_tipologia
      fsc_tipologia_attivita
      fsc_tutor
      responsabile_organizzativo_codice_fiscale
      responsabile_organizzativo_cognome
      responsabile_organizzativo_email
      responsabile_organizzativo_nome
      responsabile_organizzativo_telefono
      responsabili_scientifici {
        codice_fiscale
        cognome
        curriculum
        nome
        ruolo
      }
    }
  }
`;
